import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { H2 } from 'components/ui/Header';
import Button from 'components/ui/clickable/Button';
import Link from 'components/ui/clickable/Link';
import { pushWithSearchQuery } from 'helpers';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import configurationUiService from 'services/configuration.service';
import { toggleHelpModal } from 'store/ui';
import * as svars from 'style/variables';
import surveyCampaignService from 'services/surveyCampaign.service';
import { useKeypress } from 'tools/hooks';
import RichTextRender from 'components/ui/RichTextRender';

const HomeDescriptionContainer = styled.div`
  margin: 0 auto;
  padding: 0 ${svars.spaceNormal};
  text-align: justify;
  max-width: ${svars.textMaxWidth};
  overflow: clip auto;
`;

const LoginActionContainer = styled.div`
  text-align: center;
  padding: ${svars.spaceNormal} 0;
`;
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  max-width: ${svars.textMaxWidth};
  margin: auto;
  height: 100%;
`;

const HomeMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  padding: ${svars.spaceNormal} 0;
  margin: 0 ${svars.spaceNormal};
`;

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { home } = feedbackCampaignService.wording;
  const { commons } = configurationUiService.wording;

  const { isSurvey, displayHomeScreen } = configurationUiService;
  const onNavigate = () =>
    pushWithSearchQuery(isSurvey ? '/survey' : '/feedback', history);
  let title;
  let descriptions = [];
  let ctaText;
  let tagline;
  if (isSurvey) {
    title = surveyCampaignService.homeScreen?.title;
    descriptions = [surveyCampaignService.homeScreen?.description];
    tagline = surveyCampaignService.homeScreen?.tagline;
    ctaText = surveyCampaignService.homeScreen?.button_label;
  } else {
    title = feedbackCampaignService.wording?.home?.title;
    descriptions = [home.subtitle, home.secondSubtitle];
    ctaText = home.ctaText;
  }
  const dispatchToggleFaq = () => dispatch(toggleHelpModal());
  useKeypress('Enter', onNavigate);
  if (isSurvey && !displayHomeScreen) {
    onNavigate();
    return null;
  }
  return (
    <HomeContainer>
      <HomeMessageContainer>
        <H2
          centered
          style={{ whiteSpace: 'pre-wrap', padding: `${svars.spaceMedium} 0` }}
        >
          {tagline ? (
            <span
              style={{
                fontSize: svars.fontSizeBase,
                fontWeight: svars.fontWeightLight,
                display: 'block',
              }}
            >
              <RichTextRender content={tagline} />
            </span>
          ) : null}
          {title}
        </H2>
        <HomeDescriptionContainer>
          {descriptions.map((description, index) => (
            <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
              {description ? <RichTextRender content={description} /> : null}
            </span>
          ))}
        </HomeDescriptionContainer>
        <LoginActionContainer>
          <span>
            <Button
              gaCategory="Home"
              gaAction="access"
              onClick={onNavigate}
              data-testid="feedback-home-page-cta"
            >
              {ctaText}
            </Button>
          </span>
        </LoginActionContainer>
      </HomeMessageContainer>
      <p style={{ fontSize: svars.fontSizeSmall, textAlign: 'center' }}>
        {home.consultFAQPrefix || commons.consultFAQPrefix}{' '}
        <Link
          base
          gaCategory="Home"
          gaAction="display faq"
          onClick={dispatchToggleFaq}
          data-testid="feedback-home-consult-faq"
        >
          {home.consultFAQ || commons.consultFAQCTA}
        </Link>
      </p>
    </HomeContainer>
  );
};

export default Home;
