import { getAllSearchParams } from 'helpers';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { api } from 'app/api';
import * as Sentry from '@sentry/react';
import surveyCampaignService from 'services/surveyCampaign.service';

const initialState = {
  loading: false,
  published: false,
  preview: false,
  questionIndex: 0,
  answers: {},
  nextQuestionIndex: 0,
  // Used to focus on the next button when the user clicks on the previous button
  // We use a number instead of a boolean to allow for resetting focus events when the user
  // changes its answer
  nextButtonFocused: 0,
  publishedId: null,
};

const formatSurveyAnswers = (answers) => {
  const { questions } = surveyCampaignService;
  const finalAnswers = [];
  for (const question of questions) {
    const answer = answers[question.id];
    const answerQualifies =
      answer && surveyCampaignService.shouldDisplayQuestion(answers, question);
    if (answerQualifies) {
      finalAnswers.push({
        question: { id: question.id },
        question_type: `${question.type}Answer`,
        value:
          (question.type.startsWith('MultiChoice') &&
            answer &&
            ((question.multiple && answer.map((value) => ({ id: value }))) || [
              { id: answer },
            ])) ||
          answer,
      });
    }
  }
  return finalAnswers;
};

export const publishSurvey = createAsyncThunk(
  'survey/publish',
  async (searchQuery, { getState }) => {
    const { answers } = getState().survey;
    const payload = {
      date: new Date().toJSON(),
      query: getAllSearchParams(searchQuery),
      answers: formatSurveyAnswers(answers),
    };
    let response;
    try {
      response = await api.post('survey', payload);
    } catch (error) {
      Sentry.captureException(error);
    }
    return response ? response.data : response;
  }
);

const resetSurveyFromState = (state) => {
  state.published = initialState.published;
  state.preview = initialState.preview;
  state.questionIndex = initialState.questionIndex;
  state.answers = initialState.answers;
  state.nextQuestionIndex = initialState.nextQuestionIndex;
  state.nextButtonFocused = initialState.nextButtonFocused;
};

export const reviewSlice = createSlice({
  name: 'survey',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSurvey: (state) => {
      resetSurveyFromState(state);
    },
    setPreviewMode: (state, { payload }) => {
      state.preview = payload;
    },
    answerQuestion: (state, { payload: { id, value } }) => {
      state.answers[id] = value;
      state.nextQuestionIndex = surveyCampaignService.getNextQuestionIndex(
        state.answers,
        state.questionIndex
      );
    },
    next: (state) => {
      if (state.nextQuestionIndex !== surveyCampaignService.constructor.END) {
        state.questionIndex = state.nextQuestionIndex;
        state.nextQuestionIndex = surveyCampaignService.getNextQuestionIndex(
          state.answers,
          state.questionIndex
        );
        state.nextButtonFocused = 0;
      }
    },
    focusOnNextButton: (state) => {
      state.nextButtonFocused += 1;
    },
    previous: (state) => {
      if (state.questionIndex > 0) {
        state.nextQuestionIndex = state.questionIndex;
        state.questionIndex = surveyCampaignService.getNextQuestionIndex(
          state.answers,
          state.questionIndex,
          true
        );
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(publishSurvey.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.identified = false;
      })
      .addCase(publishSurvey.fulfilled, (state, action) => {
        state.publishedId = action.payload?.id;
        if (action.payload === 'Survey not sent') {
          state.preview = true;
        }
        state.loading = false;
        state.published = true;
        resetSurveyFromState(state);
      })
      .addCase(publishSurvey.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export const {
  resetSurvey,
  setPreviewMode,
  answerQuestion,
  next,
  previous,
  focusOnNextButton,
} = reviewSlice.actions;

// Selectors

export const selectSurveyIsComplete = (state) =>
  state.survey.questionIndex === surveyCampaignService.questions.length;
export const selectIsLastSurveyQuestion = (state) =>
  state.survey.nextQuestionIndex === surveyCampaignService.constructor.END;

export const selectLoading = (state) => state.survey.loading;
export const selectQuestionIndex = (state) => state.survey.questionIndex;

export const selectQuestion = createSelector(
  selectQuestionIndex,
  (index) => surveyCampaignService.questions[index]
);

export const selectSurveyHeaderTitle = createSelector(
  selectQuestion,
  // Survey header title is whether the section name of the question or the survey title
  (question) => question?.section_name || surveyCampaignService.title
);
export const selectQuestionValue = createSelector(
  selectQuestion,
  (state) => state.survey.answers,
  (question, answers) => answers[question?.id]
);

export const selectNextIsAuthorized = createSelector(
  selectQuestion,
  selectQuestionValue,
  (question, value) => (question.required ? !!value : true)
);
export const selectNextButtonFocused = (state) =>
  state.survey.nextButtonFocused;

export const selectProgress = createSelector(
  (state) => state.survey.nextQuestionIndex,
  (state) => state.survey.questionIndex,
  selectNextIsAuthorized,
  (nextQuestionIndex, questionIndex, nextIsAuthorized) => {
    const index = (nextIsAuthorized && nextQuestionIndex) || questionIndex;
    return (
      (index === surveyCampaignService.constructor.END && 1) ||
      (surveyCampaignService.questions?.length &&
        index / (surveyCampaignService.questions.length || 1)) ||
      0
    );
  }
);

export const selectLastPublished = (state) => state.survey.publishedId;

export const selectPreview = (state) => state.review.preview;

export default reviewSlice.reducer;
