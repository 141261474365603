import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  useHistory,
  Redirect,
  Route,
  Link as RouterLink,
  Switch,
} from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';

import { resetFeedback, setPreviewMode } from 'store/review';

import usePageTracking, { logEvent } from 'app/analytics';
import HelpModal from 'components/HelpModal';
import PublishedPage from 'components/PublishedPage';
import RouteNotFoundPage from 'components/RouteNotFoundPage';
import ConceptHighlight from 'components/ui/ConceptHighlight';
import ConceptSuggestions from 'components/ui/ConceptSuggestions';
import Segment from 'components/ui/Segment';
import FadeInOut from 'components/ui/animation/FadeInOut';
import FeedbackFormPage from 'page/feedback/FeedbackFormPage';
import FeedbackSearchPage from 'page/feedback/FeedbackSearchPage';
import SurveyPage from 'page/survey/SurveyPage';
import SurveyPublishedPage from 'page/survey/SurveyPublishedPage';
import HomePage from 'page/Home';
// eslint-disable-next-line import/no-unresolved
import BwCopyright from 'components/ui/BwCopyright';
import commonConfigurationService from 'services/configuration.service';
import GlobalStyle from 'style/globalStyles';
import defaultTheme from 'style/theme/default';
import * as svars from 'style/variables';

const AppContainer = styled.div`
  height: ${svars.appContainerHeight};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${svars.spaceNormal} 0;

  & img,
  svg {
    max-width: 100%;
  }

  & a {
    display: flex;
  }
`;

const WithTracker = () => {
  usePageTracking();
  return null;
};

function CustomerLogo({ logoUrl, resetStore }) {
  const history = useHistory();
  const linkToHome = !history.location?.pathname?.includes('publish');
  const redirect = () => {
    resetStore();
    logEvent({
      category: 'Navigation',
      action: 'Click logo',
    });
  };
  return (
    <LogoContainer id="logo">
      <RouterLink
        tabIndex={-1}
        to={
          linkToHome
            ? ({ search }) => {
                return {
                  pathname: '/',
                  search,
                };
              }
            : {}
        }
        onClick={linkToHome ? redirect : null}
      >
        <img
          style={{ height: svars.customerLogoHeight }}
          src={logoUrl}
          alt="Logo"
        />
      </RouterLink>
    </LogoContainer>
  );
}

CustomerLogo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  resetStore: PropTypes.func.isRequired,
};

function App({ logoUrl, isWellFormedUrl, preview }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPreviewMode(preview));
  }, [dispatch, preview]);
  const { theme, displayBwLogo, isFeedbackCampaign } =
    commonConfigurationService;
  return (
    <ThemeProvider theme={theme || defaultTheme}>
      <GlobalStyle />
      <WithTracker />
      <Switch>
        <Route exact path="/not-found" component={RouteNotFoundPage} />
        {isWellFormedUrl ? null : <Redirect to="/not-found" />}
        <AppContainer>
          {isWellFormedUrl ? (
            <CustomerLogo
              logoUrl={logoUrl}
              resetStore={() => dispatch(resetFeedback())}
            />
          ) : null}

          <FadeInOut
            show
            style={{
              maxWidth: svars.rootMaxWidth,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: '60%',
              // flexGrow: 1,
            }}
          >
            <Segment style={{ overflow: 'hidden' }}>
              <Switch>
                <Route exact path="/">
                  <HomePage />
                </Route>
                {isFeedbackCampaign ? (
                  <>
                    <Route exact path="/feedback">
                      <FeedbackSearchPage />
                    </Route>
                    <Route path="/feedback/:category?">
                      <FeedbackFormPage />
                    </Route>
                    <Route path="/published">
                      <PublishedPage />
                    </Route>
                  </>
                ) : (
                  <>
                    <Route exact path="/survey">
                      <SurveyPage />
                    </Route>
                    <Route path="/survey/published">
                      <SurveyPublishedPage />
                    </Route>
                  </>
                )}

                <Redirect to="/not-found" />
              </Switch>
            </Segment>
            {isFeedbackCampaign ? (
              <>
                <ConceptHighlight />
                <ConceptSuggestions />
              </>
            ) : null}
          </FadeInOut>
        </AppContainer>
        {/* <ThemeSelector style={{ position: "absolute" }} /> */}
      </Switch>
      {displayBwLogo ? <BwCopyright /> : null}
      <HelpModal />
    </ThemeProvider>
  );
}

App.propTypes = {
  isWellFormedUrl: PropTypes.bool,
  preview: PropTypes.bool,
  logoUrl: PropTypes.string.isRequired,
};
App.defaultProps = {
  isWellFormedUrl: true,
  preview: false,
};

export default App;
