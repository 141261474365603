import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as svars from 'style/variables';

const baseSpaceSizeRem = 0.8;
const compactSpaceSizeRem = 0.55;

const ChoiceButtonContainer = styled.button`
  ${({ hidden, compact, fluid }) => {
    const baseSpace = compact ? compactSpaceSizeRem : baseSpaceSizeRem;
    const width = fluid ? `calc(100% - ${baseSpace}rem)` : 'null';
    return css`
      display: ${hidden ? 'hidden' : 'inline-flex'};
      width: ${width};
      min-height: ${2 * baseSpace}rem;
      padding: ${baseSpace}rem ${baseSpace * 1.5}rem;
      margin: ${baseSpace / 3}rem ${baseSpace / 2}rem;
      font-size: ${compact ? svars.fontSizeSmall : svars.fontSizeMedium};
    `;
  }}
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  min-width: ${({ horizontal }) => (horizontal ? '3.75rem' : '15rem')};
  appearance: none;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background: ${({ theme, secondary, selected, isOdd, disabled }) =>
    (selected &&
      ((disabled && disabled && theme.choiceButton.disabled.background) ||
        theme.choiceButton.selected.background)) ||
    (secondary && theme.choiceButton.secondary.background) ||
    (isOdd && theme.choiceButton.backgroundOdd) ||
    theme.choiceButton.background};
  color: ${({ theme, selected }) =>
    selected ? theme.choiceButton.selected.color : theme.choiceButton.color};
  border-radius: ${({ theme }) => theme.choiceButton.borderRadius};
  box-shadow: ${({ theme }) => theme.choiceButton.boxShadow};
  backdrop-filter: ${({ theme }) => theme.choiceButton.backdrop};
  -webkit-backdrop-filter: ${({ theme }) => theme.choiceButton.backdrop};
  border: ${({ theme }) => theme.choiceButton.border};
  transition: all 0.15s ease-in-out;

  &:hover {
    background: ${({ theme, selected }) =>
      selected ? theme.primary : theme.primaryLightHover};
    color: ${({ theme, selected, secondary }) =>
      selected || secondary
        ? theme.choiceButton.selected.color
        : theme.choiceButton.color};
    box-shadow: ${({ theme }) => theme.choiceButton.hover.boxShadow};
    border-color: ${({ theme }) => theme.choiceButton.hover.borderColor};
  }
`;

const ChoiceButton = ({ children, icon, ...props }) => (
  <ChoiceButtonContainer {...props}>
    {children}
    {icon ? (
      <span
        style={{
          display: 'flex',
          paddingLeft: svars.spaceNormal,
        }}
      >
        {icon}
      </span>
    ) : null}
  </ChoiceButtonContainer>
);

ChoiceButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  isOdd: PropTypes.bool,
  compact: PropTypes.bool,
  centered: PropTypes.bool,
};
ChoiceButton.defaultProps = {
  icon: null,
  isOdd: false,
  compact: false,
  centered: false,
};

export default ChoiceButton;
