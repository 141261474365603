import { useCallback, useEffect } from 'react';
import { IoArrowBack, IoArrowForward, IoSend } from 'react-icons/io5';

import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  selectIsLastSurveyQuestion,
  next,
  selectLoading,
  publishSurvey,
  previous,
  selectNextIsAuthorized,
  selectQuestionIndex,
  selectNextButtonFocused,
} from 'store/survey';

import Button from 'components/ui/clickable/Button';
import { pushWithSearchQuery } from 'helpers';
import * as svars from 'style/variables';
import PublishDisclaimer from 'components/PublishDisclaimer';
import { toggleConfidentialityPolicy } from 'store/ui';
import configurationUiService from 'services/configuration.service';

const Container = styled.div`
  font-size: ${svars.fontSizeBase};
  display: flex;
  justify-content: space-between;
  margin: 0 ${svars.spaceMedium};

  & button {
    flex-shrink: 0;
  }
`;

const ConsentMessageContainer = styled.div`
  display: flex;
  padding: 0 ${svars.spaceMedium};
  font-size: ${svars.fontSizeSmall};
  text-align: right;
  justify-content: flex-end;
`;

function SurveyControls() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector(selectLoading);
  const isLastQuestion = useSelector(selectIsLastSurveyQuestion);
  const questionIndex = useSelector(selectQuestionIndex);
  const nextIsAuthorized = useSelector(selectNextIsAuthorized);
  const nextButtonFocused = useSelector(selectNextButtonFocused);

  const {
    CTAComponent,
    wording: { commons },
  } = configurationUiService;

  const [privacyNoticePrefix, privacyPolicy] =
    commons.whenContributionSentence || [];

  const goToPublished = () => pushWithSearchQuery('/survey/published', history);
  const handlePrevious = () => {
    dispatch(previous());
  };

  const handleNext = useCallback(() => {
    document.querySelector('#next-send')?.blur();
    document.querySelector('#survey-page')?.focus();
    if (isLastQuestion) {
      dispatch(publishSurvey(history.location.search)).then(goToPublished);
    }
    dispatch(next());
  }, [isLastQuestion, history?.location?.search]);
  const showConfidentialityPolicy = useCallback(
    () => dispatch(toggleConfidentialityPolicy()),
    [dispatch]
  );
  useEffect(() => {
    if (nextButtonFocused) {
      document.querySelector('#next-send')?.focus();
    }
  }, [nextButtonFocused]);

  return (
    <div style={{ margin: '1rem 0' }}>
      <Container>
        <Button
          gaCategory="Survey"
          gaAction="previous question"
          gaLabel={`${isLastQuestion}`}
          secondary
          disabled={questionIndex === 0 || isLoading}
          onClick={handlePrevious}
          style={{
            fontSize: svars.fontSizeLarge,
            opacity: questionIndex === 0 ? 0 : 1,
          }}
          data-testid="previous-question"
        >
          <IoArrowBack />
        </Button>

        {isLastQuestion ? (
          <Button
            gaCategory="Survey"
            gaAction="Send response"
            data-testid="fb-campaign-preview-pane-send-button"
            gaLabel={`${isLastQuestion}`}
            id="next-send"
            onClick={handleNext}
            disabled={!nextIsAuthorized || isLoading}
            loading={isLoading}
          >
            {commons.sendSurveyCta}
            <IoSend style={{ marginLeft: svars.spaceNormal }} />
          </Button>
        ) : (
          <CTAComponent
            gaCategory="Survey"
            gaAction="Next question"
            id="next-send"
            key="button-next"
            onClick={handleNext}
            disabled={!nextIsAuthorized || isLoading}
            triggerClick={nextButtonFocused}
            loading={isLoading}
            style={{ fontSize: svars.fontSizeLarge }}
            data-testid="next-button"
          >
            <IoArrowForward />
          </CTAComponent>
        )}
      </Container>
      <ConsentMessageContainer data-testid="fb-campaign-preview-pane-consent-message">
        {(isLastQuestion && nextIsAuthorized && (
          <PublishDisclaimer
            showConfidentilityPolicy={showConfidentialityPolicy}
            privacyNoticePrefix={privacyNoticePrefix}
            privacyPolicy={privacyPolicy}
          />
        )) ||
          null}
      </ConsentMessageContainer>
    </div>
  );
}

export default SurveyControls;
