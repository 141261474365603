import { configureStore } from '@reduxjs/toolkit';
import reviewReducer from 'store/review';
import searchReducer from 'store/search';
import uiReducer from 'store/ui';
import surveyReducer from 'store/survey';

import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

export const store = configureStore({
  reducer: {
    review: reviewReducer,
    ui: uiReducer,
    search: searchReducer,
    survey: surveyReducer,
  },
  enhancers: [sentryReduxEnhancer],
});
