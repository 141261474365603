import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from 'app/store';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import commonConfigurationService from 'services/configuration.service';
import ontologyService from 'services/ontology.service';
import feedbackCampaignService from 'services/feedbackCampaign.service';
import surveyCampaignService from 'services/surveyCampaign.service';
import * as serviceWorker from 'serviceWorker';

import config from 'config';

import App from './App';
import './index.css';

if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.npm_package_version,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

const isWellFormedUrl = window.__IS_WELL_FORMED_URL__;
delete window.__IS_WELL_FORMED_URL__;

const preview = window.__IS_PREVIEW__;
const logoUrl = window.__LOGO_URL__;
delete window.__IS_PREVIEW__;

const configuration = window.__CONFIGURATION__;
// Reroute if concept is specified
if (configuration?.concept) {
  window.history.replaceState(
    null,
    window.document.title,
    `/feedback/${configuration.concept}${window.location.search}`
  );
}

delete window.__CONFIGURATION__;
const randomize = configuration?.randomize;

if (isWellFormedUrl) {
  const isSurvey = !!configuration?.questions;
  commonConfigurationService.load(configuration, isSurvey);
  if (isSurvey) {
    surveyCampaignService.load(configuration);
  } else {
    feedbackCampaignService.load(configuration);
    ontologyService.load(
      configuration.search_ontology,
      configuration.highlight_concepts,
      randomize
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App
          logoUrl={logoUrl}
          isWellFormedUrl={isWellFormedUrl}
          preview={preview}
        />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
