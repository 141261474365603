import { useCallback, useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';

import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import ClickableIcon from 'components/ui/clickable/ClickableIcon';
import * as svars from 'style/variables';
import { useKeypress, useOnClickOutside } from 'tools/hooks';
import Document from 'flexsearch/dist/module/document';
import { lighten } from 'polished';

const LIST_HEIGHT = '9rem';

const DropdownWrapper = styled.div`
  position: relative;
`;
const DropdownListWrapper = styled.div``;

const openKeyframe = keyframes`
from {
    max-height: 0rem;
  }
  to {
    max-height: ${LIST_HEIGHT};
  }
`;
const closeKeyframe = keyframes`
from {
    max-height: ${LIST_HEIGHT};
  }
  to {
    max-height: 0rem;
  }
`;

const DropdownList = styled.div`
  z-index: 2;
  background: ${svars.colorWhite};
  border: ${({ focus, theme }) =>
    focus ? `1px solid ${theme.input.borderColor}` : 'none'};
  ${({ up }) => (up ? 'border-bottom: none;' : 'border-top: none;')}
  border-radius: ${({ up }) => (up ? '0.5em 0.5em 0 0' : '0 0 0.5em 0.5em')};
  position: absolute;
  margin: 0;
  padding: 0;
  overflow: clip auto;
  &:hover {
    border-color: ${({ theme }) => theme.input.focus.borderColor};
  }
  ${({ focus, theme }) =>
    focus
      ? css`
          border-color: ${theme.input.focus.borderColor};
          animation: ${openKeyframe} 0.2s forwards;
        `
      : focus === false
        ? css`
            animation: ${closeKeyframe} 0.2s forwards 0.15s;
          `
        : 'max-height: 0'};
  li {
    /* white-space: nowrap; */
    border-bottom: 1px solid ${svars.colorGreyLightest};
    overflow: hidden;
    text-overflow: ellipsis;
    padding: ${svars.spaceMedium} ${svars.spaceLarge};
    list-style: none;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.primaryLightHover};
    }
  }
`;

const InputContainer = styled.div`
  border: 1px solid
    ${({ theme, focus }) =>
      focus ? theme.input.focus.borderColor : theme.input.borderColor};
  ${({ theme, focus, up }) => {
    const border = `1px solid ${lighten(0.6, theme.fontColorBase)}`;
    return up && focus
      ? `border-top: ${border}`
      : focus
        ? `border-bottom: ${border}`
        : '';
  }};
  border-radius: ${({ focus, up }) =>
    focus ? (up ? '0 0 0.5em 0.5em' : '0.5em 0.5em 0 0') : '0.5em'};

  display: flex;
  align-items: center;
  background: ${({ theme: { input } }) => input.background};
  input {
    padding: ${svars.spaceSmall} ${svars.spaceNormal};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    width: 100%;
    border: 0;
    cursor: ${({ option }) => (option ? 'pointer' : 'caret')};
  }
`;

const Container = styled.div`
  flex-grow: 1;

  .input:focus {
    outline: none;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  & :focus {
    outline: none;
  }
`;
function min0(x, y) {
  return x < y ? (x ? x : 1) : y;
}

function getWindowHeight() {
  const { innerHeight: height } = window;
  return height;
}

const SearchableDropdown = ({
  options,
  onChange,
  value,
  placeholder,
  alwaysUp,
  noResultText,
}) => {
  const [searchIndex, setSearchIndex] = useState(null);
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(null);
  const searchInput = useRef();
  const dropdownContainer = useRef();
  const searchContainer = useRef();
  const [up, setUp] = useState(true);
  const [searchOptions, setSearchOptions] = useState(options);
  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    // Handle window resize
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Initialize search index
    const index = new Document({
      document: {
        store: true,
        id: 'key',
        index: ['searchText'],
      },
      // tokenize: "full",
      tokenize: 'forward',
      charset: 'latin:simple',
      language: 'fr',
    });
    options.forEach((item) => index.add(item));
    setSearchIndex(index);
  }, [options]);

  useEffect(() => {
    let results = [];
    if (search.length) {
      const searched = searchIndex.search(search, { enrich: true });
      if (searched.length) {
        results = searched[0].result.map(({ doc }) => doc);
      } else {
        results = [];
      }
    } else {
      results = options;
    }
    setSearchOptions(results);
  }, [search, searchIndex, options]);

  const handleBlur = useCallback(() => {
    if (focus) {
      setSearch('');
      setFocus(false);
      searchInput.current.blur();
    }
  }, [focus, searchInput]);

  const handleFocus = useCallback(() => {
    searchInput.current.focus();
    setFocus(true);
    setSearch('');
  }, [searchInput]);

  const handleClear = useCallback(() => {
    searchInput.current.focus();
    setFocus(true);
    onChange(null);
    setSearch('');
  }, [searchInput, onChange]);

  const iconClick = useCallback(() => {
    focus ? (search ? handleClear() : handleBlur()) : handleFocus();
  }, [focus, search, handleBlur, handleFocus, handleClear]);

  const onEnter = useCallback(() => {
    if (searchOptions.length === 1) {
      onChange(searchOptions[0]);
      handleBlur();
    }
  }, [searchOptions, onChange, handleBlur]);

  useKeypress('Enter', onEnter);
  useOnClickOutside(dropdownContainer, handleBlur);
  useKeypress('Escape', handleBlur);
  return (
    <Container
      ref={dropdownContainer}
      option={value ? 'true' : null}
      focus={focus}
      up={up}
    >
      <DropdownWrapper>
        <InputContainer focus={focus} up={up} ref={searchContainer}>
          <input
            id="search"
            type="search"
            ref={searchInput}
            value={
              value
                ? options.find(({ value: itemValue }) => itemValue === value)
                    .label
                : search
            }
            placeholder={placeholder}
            onChange={(input) => {
              setSearch(input.target.value);
              onChange(null);
            }}
            onFocus={() => {
              setSearch('');
              onChange(null);
              setFocus(true);
              if (!alwaysUp) {
                setUp(
                  searchContainer.current.offsetParent.offsetTop +
                    1.8 *
                      min0(searchOptions.length, 5) *
                      searchContainer.current.offsetHeight >
                    windowHeight
                );
              }
            }}
            autoComplete="off"
          />
          <ClickableIcon
            icon={
              focus ? search ? <FaTimes /> : <FaChevronUp /> : <FaChevronDown />
            }
            onClick={iconClick}
            style={{
              background: 'none',
              boxShadow: 'none',
              fontSize: svars.fontSizeBase,
              padding: svars.spaceNormal,
              transform: 'scale(1)',
            }}
          />
        </InputContainer>
        <DropdownListWrapper>
          {searchContainer.current && (
            <DropdownList
              up={up}
              focus={focus}
              style={{
                ...(up ? { bottom: '100%', top: 'auto' } : {}),
                // Remove 2px to account for borders
                width: 'calc(100% - 2px)',
              }}
            >
              {searchOptions.length ? (
                searchOptions.map((item) => (
                  <li
                    key={item.key}
                    onClick={() => {
                      onChange(item);
                      setFocus(false);
                    }}
                  >
                    {item.label}
                  </li>
                ))
              ) : (
                <li
                  style={{
                    cursor: 'auto',
                    background: 'transparent',
                    fontStyle: 'italic',
                    color: svars.fontColorLightest,
                  }}
                >
                  {noResultText}
                </li>
              )}
            </DropdownList>
          )}
        </DropdownListWrapper>
      </DropdownWrapper>
    </Container>
  );
};

SearchableDropdown.propTypes = {
  noResultText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alwaysUp: PropTypes.bool,
  searchIndex: PropTypes.object,
};
SearchableDropdown.defaultProps = {
  noResultText: 'Pas de résultat',
  placeholder: 'Rechercher',
  value: null,
  alwaysUp: false,
  searchIndex: null,
};

export default SearchableDropdown;
