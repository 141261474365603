import * as svars from 'style/variables';
import {
  adjustHue,
  darken,
  lighten,
  linearGradient,
  setLightness,
  setSaturation,
  transparentize,
} from 'polished';
import getMostContrastedColor from 'tools/color';

const makeTheme = (
  name,
  primary,
  fontColorBase = svars.fontColorBase,
  fontColorPrimary = null,
  relaxed,
  {
    appBackground,
    fontSizeBase = svars.fontSizeRoot,
    fontFamily = svars.fontFamilySansSerif,
    borderRadius = '6px',
    tagBackground,
    tagBackgroundColorHover,
    tagBorderColor,
    choiceBackgroundOdd,
  }
) => {
  const fontColorLight = lighten(0.3, fontColorBase);
  const fontColorLighter = lighten(0.65, fontColorBase);
  const fontColorLightest = lighten(0.75, fontColorBase);
  const primaryDisabled = transparentize(0.5, primary);
  const primaryHovered = darken(0.05, primary);
  const primarySaturatedLightened = setSaturation(
    1,
    setLightness(0.97, primary)
  );
  const primaryBoxShadow =
    `0px 1px 3px 1px ${transparentize(0.8, primary)}` || svars.baseBoxShadow;
  const finalFontColorPrimary =
    fontColorPrimary ||
    getMostContrastedColor(primary, svars.fontColorBase, svars.colorWhite);
  console.log(
    'finalFontColorPrimary',
    finalFontColorPrimary,
    getMostContrastedColor(primary, svars.fontColorBase, svars.colorWhite),
    primary,
    svars.fontColorBase,
    svars.colorWhite
  );
  return {
    name,
    relaxed,
    primary,
    fontColorBase,
    fontColorLight,
    fontColorLightest, // basically white
    fontFamily,
    fontSizeRoot: fontSizeBase,
    appBackground:
      appBackground ||
      linearGradient({
        colorStops: [
          `${adjustHue(40, primarySaturatedLightened)} 0%`,
          `${adjustHue(20, primarySaturatedLightened)} 50%`,
          `${adjustHue(0, primarySaturatedLightened)} 100%`,
        ],
        toDirection: 'to right',
        fallback: '#F',
      }),
    primaryDisabled,
    primaryHovered,
    primaryLightHover: setLightness(0.95, primary),
    fontColorPrimary: finalFontColorPrimary,
    fontColorPrimaryHover: darken(0.05, finalFontColorPrimary),
    fontLighterBoxShadow: `${fontColorLighter} 0px 1px 4px`,
    card: {
      // to use for segments, icons and modals
      background: transparentize(0.2, 'white'),
      boxShadow: 'transparent',
      borderRadius: borderRadius,
      borderColor: svars.whiteTransparentStrong,
    },
    input: {
      // to use for inputs: searchBar, textArea, searchableDropdown, TextInput
      background: svars.whiteTransparentLight,
      borderRadius: borderRadius,
      borderColor: fontColorLighter,
      borderWidth: '1px',
      boxShadow: svars.baseBoxShadow,
      hover: {
        borderColor: primaryHovered,
      },
      focus: {
        borderColor: primary,
        boxShadow: primaryBoxShadow,
      },
    },
    tag: {
      background: tagBackground || svars.colorGreyLightest,
      borderRadius: borderRadius,
      borderColor: tagBorderColor || 'transparent',
      borderWidth: '1px',
      boxShadow: svars.baseBoxShadow,
      transition: 'null',
      hover: {
        background:
          tagBackgroundColorHover || darken(0.05, svars.colorGreyLightest),
        borderColor: darken(
          0.05,
          tagBackgroundColorHover || darken(0.05, svars.colorGreyLightest)
        ),
      },
    },
    choiceButton: {
      background: svars.whiteTransparentMedium,
      borderRadius: borderRadius,
      border: '1px solid transparent',
      boxShadow: primaryBoxShadow,
      backdrop: 'null',
      color: fontColorBase,
      backgroundOdd: choiceBackgroundOdd || svars.whiteTransparentMedium,
      hover: {
        background: primaryHovered,
        borderColor: primary,
        boxShadow: primaryBoxShadow,
      },
      selected: {
        background: primary,
        color: finalFontColorPrimary,
      },
      disabled: {
        background: primaryDisabled,
      },
      secondary: {
        background: 'transparent',
        borderColor: primary,
        hover: {
          background: primary,
          color: finalFontColorPrimary,
        },
      },
    },
    searchCard: {
      boxShadow: svars.baseBoxShadow,
      backdrop: 'null',
      border: `1px solid ${svars.colorGreyLight}`,
      borderRadius: borderRadius,
      background: svars.colorWhite,
      hover: {
        background: svars.colorWhiteDarker,
        borderColor: primary,
        boxShadow: primaryBoxShadow,
      },
      selected: {
        background: primary,
      },
    },
  };
};

export default makeTheme;
