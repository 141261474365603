// We use a service because it is a simplified redux store and the data stocked here is static
// during one session (It is only loaded at the SPA loading)
class SurveyCampaignService {
  // end of survey index symbol (static)
  static END = 'end';
  constructor() {
    this.questions = [];
    this.homeScreen = {};
  }
  get dump() {
    return JSON.stringify(this);
  }

  /* shouldDisplayQuestion - Check if the question should be displayed based on the answers
   * @param {Object} answers - The answers object
   * @ param {Object} question - The question object
   * @return {boolean} - If the question should be displayed
   */
  shouldDisplayQuestion(answers, question) {
    if (question.conditions?.length) {
      let answer;
      for (const condition of question.conditions) {
        answer = answers[condition?.question?.id];
        if (condition.values.some(({ id }) => answer?.includes(id))) {
          continue;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  /* getNextQuestionIndex - Get the next question index based on the current index and the answers
   * @param {Object} answers - The answers object
   * @param {number} currentIndex - The current question index
   * @param {boolean} goBack - If the user goes backward
   * @return {number} - The next question index
   */
  getNextQuestionIndex(answers, currentIndex, goBack = false) {
    if (currentIndex === SurveyCampaignService.END) {
      return SurveyCampaignService.END;
    }
    const nextIndex = goBack ? currentIndex - 1 : currentIndex + 1;
    if (nextIndex > this.questions?.length - 1) {
      return SurveyCampaignService.END;
    }
    const nextQuestion = this.questions[nextIndex];
    if (nextQuestion && !this.shouldDisplayQuestion(answers, nextQuestion)) {
      return this.getNextQuestionIndex(answers, nextIndex, goBack);
    }
    return nextQuestion ? nextIndex : SurveyCampaignService.END;
  }

  load({ title, questions, end_screen, home_screen = {} }) {
    this.title = title;
    this.questions = questions.map((question) => {
      return {
        ...question,
        options: question.options?.map(({ id, display_name }) => {
          return {
            key: id,
            value: id,
            label: display_name,
          };
        }),
      };
    });
    this.endScreen = end_screen;
    this.homeScreen = home_screen;
  }
}

const surveyCampaignService = new SurveyCampaignService();

export default surveyCampaignService;
