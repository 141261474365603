import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { focusOnNextButton, selectQuestion } from 'store/survey';
import { toggleHelpModal } from 'store/ui';

import FadeInOut from 'components/ui/animation/FadeInOut';
import * as svars from 'style/variables';

import SurveyHeader from './SurveyHeader';
import SurveyQuestion from './SurveyQuestion';
import SurveyControls from './SurveyControls';

const SurveyPageContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  display: flex;
  padding: 0;
  box-sizing: border-box;
`;

const SurveyPage = () => {
  const dispatch = useDispatch();
  const showFaq = useCallback(() => dispatch(toggleHelpModal()), [dispatch]);
  const { id: questionId } = useSelector(selectQuestion);
  const focusNext = useCallback(() => {
    dispatch(focusOnNextButton());
  }, []);
  return (
    <SurveyPageContainer id="survey-page">
      <FadeInOut
        show
        style={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: `${svars.spaceSmall} ${svars.spaceNormal}`,
          }}
          data-testid="feedback-header-slide"
        >
          <SurveyHeader showFaq={showFaq} />
        </div>
        <SurveyQuestion key={questionId} focusNext={focusNext} />
        <SurveyControls />
      </FadeInOut>
    </SurveyPageContainer>
  );
};

export default SurveyPage;
