import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import amplitude from 'amplitude-js';

import config from 'config';

const initializeAmplitude = () => {
  // Initialize Amplitude
  if (config.amplitude.key) {
    amplitude.getInstance().init(config.amplitude.key, null, {
      cookieExpiration: 365,
      cookieName: 'Bw-Amplitude',
      batchEvents: true,
      eventUploadThreshold: 2,
      eventUploadPeriodMillis: 4000,
      appVersion: process.env.REACT_APP_VERSION,
    });
    return true;
  }
  return false;
};

export const logEvent = ({ category, action, label, nonInteraction }) => {
  const logProps = { category };
  if (label) logProps.label = label;
  if (nonInteraction) logProps.nonInteraction = nonInteraction;

  if (config.ga.property) ReactGA.event({ action, ...logProps });
  if (config.amplitude.key) amplitude.getInstance().logEvent(action, logProps);
};

export /**
 * Set user id to analytics apps (used at login time).
 *
 * @param {*} userId the user id.
 */
const setAnalyticsUserGlobally = (userId) => {
  ReactGA.set({ userId });
  if (config.amplitude.key) amplitude.getInstance().setUserId(userId);
};

export /**
 * Forget user id on analytics apps (used at logout time).
 *
 */
const forgetUserGlobally = () => {
  setAnalyticsUserGlobally(null);
  if (config.amplitude.key) amplitude.getInstance().regenerateDeviceId();
};

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState({
    ga: false,
    amplitude: false,
  });
  useEffect(() => {
    const analyticsStatus = {
      amplitude: initializeAmplitude(),
    };
    if (Object.values(analyticsStatus).some((x) => x)) {
      setInitialized(analyticsStatus);
    }
  }, []);
  useEffect(() => {
    if (initialized.ga) {
      const pageUrl = location.pathname + location.search;
      ReactGA.set({ page: pageUrl });
      ReactGA.pageview(pageUrl);
    }
    if (initialized.amplitude) {
      amplitude.getInstance().logEvent('page view', {
        path: location.pathname,
        search: location.search,
      });
    }
  }, [initialized, location]);
};

export const withAnalytics =
  (WrappedComponent, logMethod = 'onClick') =>
  ({ gaCategory, gaAction, gaLabel, ...props }) => {
    if (!gaCategory) return <WrappedComponent {...props} />;

    let logMethodValue = props[logMethod];

    logMethodValue = useCallback(
      (...params) => {
        logEvent({
          category: gaCategory,
          action: gaAction,
          label: gaLabel,
        });
        if (props[logMethod]) {
          props[logMethod](...params);
        }
      },
      [gaCategory, gaAction, gaLabel, props]
    );
    const finalProps = { [logMethod]: logMethodValue };
    return <WrappedComponent {...props} {...finalProps} />;
  };

export default usePageTracking;
